.main {
    width: 318px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.4s;
    animation: ease-in-out;
    background:white;

}

.main:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px -5px;
}



.ImageContainer {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 200px;
}

.cardContent {
    margin: 1rem;
    margin-top: 0.5rem;
}

h3,p {
margin: 0;
padding: 0;
}
.cardTitle {
    margin-bottom: 0.5rem;
}
.btn {
    display: flex;
    justify-content: center;
}
.btn button {
    padding: 0.5rem;
    background-color: white;
    border: none;
    transition: 0.2s;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
}

.btn button:hover {
    background: rgb(177, 230, 230);
    transform: scale(1.0);
}

a {
    text-transform: uppercase;
    color: teal;
    text-decoration: none;
    font-weight: bold;
}

img {
    height: fit-content;
    width: fit-content;
}
